<template>
  <b-form @submit.stop.prevent>
    <div class="d-flex flex-row flex-wrap">
      <div class="col-12 px-2 mb-2">
        <label class="font-weight-bold" for="stationName">ชื่อแบนเนอร์</label>

        <b-form-input
          id="stationName"
          :state="invalid ? validation.form.banner_title.required : null"
          type="text"
          :disabled="disabled"
          v-model="form.banner_title"
        ></b-form-input>

        <b-form-invalid-feedback id="stationName-feedback">
          กรุณากรอกชื่อแบนเนอร์
        </b-form-invalid-feedback>
      </div>
    </div>

    <div class="d-flex flex-row my-3">
      <div class="col-12 px-2">
        <label class="font-weight-bold ft-s-14" for="bannerImg"
          >อัพโหลดแบนเนอร์</label
        >

        <b-button
          size="sm"
          variant="primary"
          class="mx-2"
          :disabled="disabled"
          @click="toggleAttachFile"
        >
          เลือกไฟล์
        </b-button>

        <small class="text-black-50 ml-2 font-weight-bold"
          >*JPG, PNG ขนาดไม่เกิน 10MB (ขนาดแนะนำ {{ width }} x
          {{ height }} px)</small
        >

        <!-- <small
          v-if="
            (invalid &&
              validation.form.file &&
              !validation.form.file.required) ||
              (invalid &&
                validation.form.keyImg &&
                !validation.form.keyImg.required)
          "
          class="text-danger"
        >
          กรุณาเลือกไฟล์
        </small> -->

        <input
          v-show="false"
          type="file"
          id="bannerImg"
          name="bannerImg"
          accept="image/*"
          ref="bannerImg"
          @change="previewImage"
        />
      </div>
    </div>

    <b-spinner
      v-show="isProcessing"
      label="processing image..."
      class="ml-2 mb-4"
    >
    </b-spinner>

    <carousel-banner
      :removable="!disabled"
      :is-loading="disabled"
      v-model="form"
    />
  </b-form>
</template>

<script>
import Compressor from "compressorjs";
import { Auth } from "../../models";
import CarouselBanner from "../carousel/BannerImage";

export default {
  props: {
    formData: Object,

    editData: {
      type: Object,
      default: () => {},
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabledInput: {
      type: Object,
      default: () => ({}),
    },
    width: {
      type: Number,
      default: 1920,
    },
    height: {
      type: Number,
      default: 1280,
    },
  },

  model: {
    prop: "formData",
    event: "change",
  },

  components: {
    CarouselBanner,
  },

  data() {
    return {
      isProcessing: false,
      form: {
        id: this.editData.id || null,
        banner_title: this.editData.banner_title || "",
        keyImg: this.editData.keyImg || null,
      },
    };
  },

  watch: {
    form: {
      deep: true,
      immediate: true,
      handler: "syncData",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  methods: {
    syncData(v) {
      this.$emit("change", v);
    },

    toggleAttachFile() {
      this.$nextTick(() => {
        this.$refs.bannerImg.click();
      });
    },

    async previewImage(event) {
      const self = this;
      const { width, height } = this;

      var input = event.target;

      const maxAllowedSize = 10 * 1024 * 1024;

      Array.from(input.files).forEach((file) => {
        if (file.size <= maxAllowedSize) {
          this.isProcessing = true;

          new Compressor(file, {
            quality: 0.8,

            width,

            height,

            resize: "cover",

            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            success(result) {
              var reader = new FileReader();

              reader.onload = async (e) => {
                self.$set(self.form, "file", result);
                self.$set(self.form, "raw", e.target.result);

                self.isProcessing = false;
              };

              reader.readAsDataURL(result);
            },
            error(err) {
              console.log(err.message);
              this.$toast.error("ไม่สามารถอัพโหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง");
            },
          });
        } else {
          this.$toast.error(
            "ไม่สามารถอัพโหลดไฟล์ขนาดใหญ่เกิน 10 MB ได้ กรุณาลองใหม่อีกครั้ง"
          );
        }
      });

      input.value = "";
    },
  },
};
</script>
