<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <small>{{ title }}</small>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <base-table
        ref="table"
        :provider="fetch"
        :fields="fields"
        :is-busy.sync="isFetching"
        :current-page.sync="currentPage"
        :number-of-pages="numberOfPages"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="10"
      >
        <!-- <template #cell(actions)="{ item }">
          <div class="text-center">
            <b-button size="sm" @click="edit(item)">{{
              actionButtonText
            }}</b-button>
          </div>
        </template> -->

        <template #cell(refresh)="{ item }">
          <div class="text-center">
            <b-button variant="danger" size="sm" @click="remove(item)"
              >ลบ</b-button
            >
          </div>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import { Video } from "../../models";
import BaseTable from "./Base";

export default {
  mixins: [formatDateMixin],

  props: {
    title: String,
    fields: Array,
    params: Object,
    actionButtonText: {
      type: String,
      default: "แก้ไข",
    },
  },

  components: {
    BaseTable,
  },

  data() {
    return {
      isFetching: false,
      currentPage: parseInt(this.$route.query.page) || 1,
      numberOfPages: 1,
      sortBy: "",
      sortDesc: false,
    };
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.refresh();
    },

    async fetch(ctx) {
      let data = [],
        additionalParams = {},
        numberOfPages = 1;

      if (this.params && this.params.keyword) {
        this.$set(additionalParams, "keyword", this.params.keyword);
      }

      if (this.sortBy) {
        this.$set(additionalParams, "order", [
          this.sortBy,
          this.sortDesc ? "DESC" : "ASC",
        ]);
      }

      this.isFetching = true;

      try {
        const promise = await Video.api().findAll(
          {
            ...additionalParams,
            limit: ctx.perPage,
            offset: (ctx.currentPage - 1) * ctx.perPage,
          },
          { save: false }
        );

        let { data: responseData = [], number_of_pages = 1 } =
          promise.response.data;

        data = responseData.map((record) => {
          return {
            ...record,
            updatedAt: record.updatedAt
              ? this.$_formatDateMixin_formatShort(record.updatedAt)
              : "-",
            createdAt: record.createdAt
              ? this.$_formatDateMixin_formatShort(record.createdAt)
              : "-",
          };
        });

        numberOfPages = number_of_pages;
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถดึงข้อมูลแบนเนอร์ได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetching = false;
      }

      this.numberOfPages = numberOfPages;

      return data;
    },

    edit(item) {
      this.$emit("edit", item);
    },

    remove(item) {
      this.$emit("delete", item);
    },

    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>
