<template>
  <b-modal
    id="create-or-update-video-modal"
    v-model="$show"
    centered
    scrollable
    size="xl"
    header-class="modal__header"
    footer-class="modal__footer"
    body-class="modal__body"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <template #modal-header="{}">
      <h3 class="font-weight-bold">เพิ่ม/แก้ไขข้อมูลวีดีโอ</h3>
      <span v-if="editData && editData.updatedAt"
        >อัพเดตล่าสุด: {{ editData.updatedAt }}</span
      >
    </template>

    <div class="col-12 px-2">
      <label class="font-weight-bold">อัพโหลดวีดีโอจากยูทูป</label>

      <b-button
        size="sm"
        variant="primary"
        class="ml-3"
        :disabled="videoSrc !== ''"
        @click="validateYouTubeUrl"
      >
        ตรวจสอบลิงก์
      </b-button>
    </div>

    <div class="d-flex flex-row flex-wrap px-2 my-4 justify-content-between">
      <label
        class="font-weight-bold ft-s-14 col-6 col-md-2 mb-0"
        for="stationName"
        >ตั้งชื่อคลิปวีดีโอยูทูป:</label
      >
      <b-form-input
        id="stationName"
        type="text"
        class="col-6 col-md-10 mb-3"
        :disabled="videoSrc !== ''"
        v-model="videoName"
      ></b-form-input>

      <label
        class="font-weight-bold ft-s-14 col-6 col-md-2 mb-0"
        for="stationName"
        >วางลิงก์ YouTube ที่นี่ :</label
      >
      <b-form-input
        id="stationName"
        type="text"
        class="col-6 col-md-10"
        :disabled="videoSrc !== ''"
        v-model="youTubeUrl"
      ></b-form-input>
    </div>

    <!-- <div class="d-flex flex-row my-4">
      <div class="col-10 px-2">
        <label class="font-weight-bold" for="bannerImg"
          >อัพโหลดวีดีโอจากเครื่อง</label
        >

        <b-button
          size="sm"
          variant="primary"
          class="mx-2"
          :disabled="youtubeSrc !== ''"
          @click="onClickUploadVideo"
        >
          เลือกไฟล์
        </b-button>

        <input
          v-show="false"
          type="file"
          accept="video/*"
          @change="handleFileUpload($event)"
          ref="upload__video"
        />

      </div>
      <div class="col-2 px-2" style="text-align: end">
        <b-button
          variant="danger"
          size="sm"
          @click="clearData"
          :disabled="!videoSrc && !youtubeSrc"
          >ลบ</b-button
        >
      </div>
    </div> -->

    <b-embed v-if="videoSrc" type="video" controls class="mt-3">
      <source :src="videoSrc" type="video/mp4" />
    </b-embed>

    <b-embed
      v-if="youtubeSrc"
      :src="youtubeSrc"
      class="mt-3"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </b-embed>

    <!-- <video-form
      v-model="form"
      :edit-data="editData"
      :disabled="isSubmiting"
      :invalid.sync="invalid"
      :validation="$v"
    ></video-form> -->

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        ยกเลิก
      </b-button>
      <b-button
        size="sm"
        variant="primary"
        @click.prevent="save"
        :disabled="!videoSrc && !youtubeSrc"
      >
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>บันทึก</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import formatDateMixin from "../../mixins/formatDateMixin";
import validatorMixin from "../../mixins/validatorMixin";
// import VideoForm from "../form/Video";
import { Auth, Video } from "../../models";
import axios from "axios";

export default {
  mixins: [formatDateMixin, validatorMixin, validationMixin],

  components: {
    // VideoForm,
  },

  props: {
    show: Boolean,
    editData: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "show",
    event: "update",
  },

  data() {
    return {
      isSubmiting: false,
      invalid: false,
      form: {},
      youTubeUrl: "",
      youtubeSrc: "",
      videoName: "",
      videoSrc: "",
      disabled: false,
    };
  },

  validations: {
    form: {
      banner_title: { required },
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },
  },

  methods: {
    onClickUploadVideo() {
      this.$nextTick(() => {
        this.$refs.upload__video.click();
      });
    },

    previewVideo() {
      const self = this;
      let reader = new FileReader();
      reader.readAsDataURL(this.form.file);
      reader.addEventListener("load", function () {
        self.videoSrc = reader.result;
      });
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;
      this.$set(this.form, "file", file);
      this.previewVideo();
      file.value = "";
    },
    async save() {
      // this.$v.$touch();

      // this.invalid = false;

      // if (this.$v.$invalid) {
      //   this.invalid = true;

      //   return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      // }

      // if (
      //   (this.editData && !this.editData.id && !this.form.file) ||
      //   (this.editData &&
      //     this.editData.id &&
      //     !this.form.keyImg &&
      //     !this.form.file)
      // ) {
      //   return this.$toast.error(`กรุณาเลือกรูปแบนเนอร์ 1 รูป`);
      // }

      let formData = {
        ...this.form,
      };

      this.isSubmiting = true;

      let promise;

      try {
        if (this.youtubeSrc) {
          if (!this.videoName)
            return this.$toast.error(`กรุณาตั้งชื่อวีดีโอยูทูป`);
          promise = await Video.api().create({
            video_name: this.videoName,
            keyVdo: this.youtubeSrc,
          });
          const { data } = promise.response;

          if (data && data.response_status == "SUCCESS") {
            this.$toast.success(`อัพโหลดวีดีโอเรียบร้อยแล้ว`);

            this.$bvModal.hide("create-or-update-video-modal");

            this.$emit("create:success");
          } else {
            this.$toast.error(`อัพโหลดวีดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
          }
        } else {
          formData = await this.uploadVideo(formData);
          console.log(formData);
          promise = await Video.api().create({
            video_name: formData.video_name,
            keyVdo: formData.keyVdo,
          });
          const { data } = promise.response;

          if (data && data.response_status == "SUCCESS") {
            this.$toast.success(`อัพโหลดวีดีโอเรียบร้อยแล้ว`);

            this.$bvModal.hide("create-or-update-video-modal");

            this.$emit("create:success");
          } else {
            this.$toast.error(`อัพโหลดวีดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`);
          }
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(error);
      } finally {
        this.isSubmiting = false;
      }
    },

    validateYouTubeUrl() {
      this.youtubeSrc = "";
      if (this.youTubeUrl != undefined || this.youTubeUrl != "") {
        var regExp =
          /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (this.youTubeUrl.match(regExp)) {
          this.youtubeSrc = this.youTubeUrl.replace("watch?v=", "embed/");
        } else {
          this.$toast.error(`ลิงก์ยูทูปไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง`);
        }
      }
    },

    async uploadVideo(form) {
      if (form.file) {
        const presignPromise = await Video.api().getUploadUrl({
          video_name: form.file.name,
          contentType: form.file.type,
        });

        const { url, fullUrl } = presignPromise.response.data;

        if (url) {
          await axios.put(url, form.file);
        }

        this.$set(form, "keyVdo", fullUrl);
        this.$set(form, "video_name", form.file.name);
      }

      return form;
    },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.invalid = false;
      this.form = {};
      this.youTubeUrl = "";
      this.videoSrc = "";
      this.youtubeSrc = "";
      this.disabled = false;
    },
  },
};
</script>
