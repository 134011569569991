<template>
  <b-modal
    id="create-or-update-station-modal"
    v-model="$show"
    centered
    scrollable
    size="xl"
    header-class="modal__header"
    footer-class="modal__footer"
    body-class="modal__body"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <template #modal-header="{}">
      <h3 class="font-weight-bold">เพิ่ม/แก้ไขข้อมูลแบนเนอร์</h3>
      <span v-if="editData && editData.updatedAt"
        >อัพเดตล่าสุด: {{ editData.updatedAt }}</span
      >
    </template>

    <banner-form
      v-model="form"
      :edit-data="editData"
      :disabled="isSubmiting"
      :invalid.sync="invalid"
      :validation="$v"
    ></banner-form>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        ยกเลิก
      </b-button>
      <b-button size="sm" variant="primary" @click.prevent="save">
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>บันทึก</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import formatDateMixin from "../../mixins/formatDateMixin";
import validatorMixin from "../../mixins/validatorMixin";
import BannerForm from "../form/Banner";
import { Auth, Banner, TeachingDare } from "../../models";
import axios from "axios";

export default {
  mixins: [formatDateMixin, validatorMixin, validationMixin],

  components: {
    BannerForm,
  },

  props: {
    show: Boolean,
    editData: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "show",
    event: "update",
  },

  data() {
    return {
      isSubmiting: false,
      invalid: false,
      form: {},
    };
  },

  validations: {
    form: {
      banner_title: { required },
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },
  },

  methods: {
    async save() {
      this.$v.$touch();

      this.invalid = false;

      if (this.$v.$invalid) {
        this.invalid = true;

        return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      }

      if (
        (this.editData && !this.editData.id && !this.form.file) ||
        (this.editData &&
          this.editData.id &&
          !this.form.keyImg &&
          !this.form.file)
      ) {
        return this.$toast.error(`กรุณาเลือกรูปแบนเนอร์ 1 รูป`);
      }

      let formData = {
        ...this.form,
      };

      this.isSubmiting = true;

      let promise;

      try {
        if (this.editData && !this.editData.id) {
          promise = await Banner.api().create({
            banner_title: formData.banner_title,
          });

          const { data = {} } = promise.response.data;

          if (data && data.id) {
            this.$set(formData, "id", data.id);
          }
        }

        formData = await this.uploadBannerImage(formData);

        promise = await Banner.api().update(formData.id, {
          banner_title: formData.banner_title,
          keyImg: formData.keyImg,
        });

        const { data } = promise.response;

        if (data && data.response_status == "SUCCESS") {
          this.$toast.success(
            `${
              this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
            }ข้อมูลแบนเนอร์เรียบร้อยแล้ว`
          );

          this.$bvModal.hide("create-or-update-station-modal");

          this.$emit("create:success");
        } else {
          this.$toast.error(
            `${
              this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
            }ข้อมูลแบนเนอร์ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
          );
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(error);
      } finally {
        this.isSubmiting = false;
      }
    },

    async uploadBannerImage(form) {
      if (form.file) {
        const presignPromise = await TeachingDare.api().getUploadUrl({
          key: `banners/${form.id}/images/${form.file.name}`,
          contentType: form.file.type,
        });

        const { url, fullUrl } = presignPromise.response.data;

        if (url) {
          await axios.put(url, form.file);
        }

        this.$set(form, "keyImg", fullUrl);
      }

      return form;
    },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.invalid = false;
      this.form = {};

      this.$emit("update:editData", {});
    },
  },
};
</script>
